/* Play.module.css */
.myUnityApp {
    visibility: hidden;
    margin: 10px auto;
    width: 800px;
    height: 600px;
    border: 1px solid #ccc;
  }
  
  .myUnityAppLoaded {
    visibility: visible;
  }
  