.passwordStrengthMessage  {
    margin-top: 10px;
    font-size: 0.9em;
    font-weight: bold;
  }
  
  .strength0 {
    /* No message for strength 0, you may want to hide it */
    display: none;
  }
  
  .strength1 {
    /* Very Weak */
    color: #ff3e36;
  }
  
  .strength2 {
    /* Weak */
    color: #ff691f;
  }
  
  .strength3 {
    /* Medium */
    color: #ffda36;
  }
  
  .strength4 {
    /* Strong */
    color: #0fdd60;
  }
  
  .strength5 {
    /* Very Strong */
    color: #12c48b;
  }
  